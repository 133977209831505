@import '../../styles/variables';

.skills {
	overflow-y: auto;
	height: $page-body-height;
	&__title {
		border-bottom: 2px solid white;
		text-align: center;
		padding-bottom: $margin / 2;
		margin: 0 auto;
	}
	.tags {
		list-style: none;
		text-align: center;
		margin: 25px auto;
		li {
			display: inline-block;
			padding: $margin;
			font-style: italic;
			position: relative;
			cursor: pointer;
			&:hover {
				.tags__value {
					display: inline-block;
					visibility: visible;
				}
			}
		}
		&__value {
			visibility: hidden;
			height: 15px;
			width: 100%;
			border: 1px solid var(--main-app-text-color);
			position: absolute;
			left: 0;
			bottom: 0;
			margin: 0 auto;
			transition: visibility $transition-speed ease;
		}
		&__weight-1 {
			font-size: 14px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 5%, var(--main-app-bg) 95%);
			}
		}
		&__weight-2 {
			font-size: 16px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 10%, var(--main-app-bg) 90%);
			}
		}
		&__weight-3 {
			font-size: 18px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 20%, var(--main-app-bg) 80%);
			}
		}
		&__weight-4 {
			font-size: 20px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 30%, var(--main-app-bg) 70%);
			}
		}
		&__weight-5 {
			font-size: 22px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 40%, var(--main-app-bg) 60%);
			}
		}
		&__weight-6 {
			font-size: 24px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 50%, var(--main-app-bg) 50%);
			}
		}
		&__weight-7 {
			font-size: 26px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 70%, var(--main-app-bg) 30%);
			}
		}
		&__weight-8 {
			font-size: 28px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 80%, var(--main-app-bg) 20%);
			}
		}
		&__weight-9 {
			font-size: 30px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 90%, var(--main-app-bg) 10%);
			}
		}
		&__weight-10 {
			font-size: 32px;
			&--graph {
				background-image: linear-gradient(90deg, var(--main-app-text-color) 10%, var(--main-app-bg) 0%);
			}
		}
	}
}
