@import '../../styles/variables';
nav {
	margin: calc(10px + 2vmin) 0;
}
.app-menu {
	list-style: none;
	align-items: center;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	max-width: calc(650px +  2vmin);
	margin: $margin auto;
	text-align: center;
	li {
		a {
			color: var(--main-app-text-color);
			text-decoration: none;
			transition: font-weight $transition-speed ease;
			font-size: calc(4px + 1.5vmin);
			&:hover {
				font-weight: bold;
				border-bottom: 4px solid var(--secondary-color);
			}
		}
		.is-active {
			font-weight: bold;
			border-bottom: 4px solid var(--secondary-color);
		}
	}
}
